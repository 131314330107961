/* Hero.css */
.hero-container {
    height: 100vh;
    width: 100vw;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* 変更: flexの方向をcolumnに設定 */
    justify-content: center;
    align-items: center;
    background: #040509;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

/* スクロールバーを隠すスタイル */
.hero-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.hero-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

.spiral {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    color: #e0ecef;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    justify-content: center;
    top: 30%;
}

@keyframes spiral {
    0% {
        --angle: 0deg;
    }

    100% {
        --angle: 360deg;
    }
}

.character {
    font-size: 2.8rem;
    color: white;
    text-transform: uppercase;
    transform: translateY(calc(sin(var(--angle)) * 80px)) scale(calc(cos(var(--angle)) * 0.5 + 0.5));
    animation: spiral 4s linear infinite;
}

.light-mode .character {
    color: black;
}

@media (max-width: 490px) {
    .character {
        font-size: 2.2rem;
    }
}

/* 追加: フッター用のスタイル */
.footer {
    margin-top: auto;
    /* フッターを下部に配置 */
    padding: 16px;
    text-align: center;
    background-color: #040509;
    color: white;
}